import { isString } from 'lodash';

export const setLocalStorage = (name, value) => {
  return localStorage.setItem(name, value);
};

export const getLocalStorage = (name) => {
  return localStorage.getItem(name);
};

export const clearLocalStorage = (name) => {
  return localStorage.clear();
};

export const formatNumber = (num) => {
  num = isString(num) ? parseFloat(num) : num;

  if (isNaN(num)) {
    return 0;
  }

  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else if (num < 1000000000) {
    return (num / 1000000).toFixed(1) + 'm';
  } else {
    return (num / 1000000000).toFixed(1) + 'b';
  }
};

export const checkForEmptyValues = (obj, optionalFields = []) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (optionalFields.includes(key)) {
        continue;
      }
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        let result = checkForEmptyValues(obj[key], optionalFields);
        if (result) {
          return `Error: The key '${key}' contains an empty value.`;
        }
      } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        return `Error: The key '${key}' has an empty value.`;
      }
    }
  }
  return null;
};

export const versionSort = (a, b) => {
  const [afirst, asecond, athird] = a.version.split('.');
  const [bfirst, bsecond, bthird] = b.version.split('.');

  if (+bfirst + 1000 > +afirst + 1000) {
    return 1;
  } else if (asecond && bsecond && +bsecond + 1000 > +asecond + 1000) {
    return 1;
  } else if (athird && bthird && +bthird + 1000 > +athird + 1000) {
    return 1;
  }

  return -1;
};
