import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import './scss/style.scss';
import { getLocalStorage } from './utils';
import { NetworkConfig } from 'src/services/apiConfig';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

NetworkConfig();

const ProtectedRoutes = () => {
  if (!getLocalStorage('accessToken')) {
    return <Navigate to="/login" replace />;
  } else {
    return <Outlet />;
  }
};

const PublicRoutes = () => {
  if (getLocalStorage('accessToken')) {
    return <Navigate to={`/dashboard`} replace />;
  } else {
    return <Outlet />;
  }
};

const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
          </Route>

          <Route element={<ProtectedRoutes />}>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Route>

          <Route exact path="*" name="Page 404" element={<Page404 />} />
        </Routes>
        <ToastContainer />
      </Suspense>
    </HashRouter>
  );
};

export default App;
